import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VApp,
    { staticStyle: { position: "fixed", height: "100%", width: "100%" } },
    [
      _c("div", { staticStyle: { height: "100%" } }, [
        _vm.loaded
          ? _c(
              "div",
              { staticStyle: { height: "100%" } },
              [
                !_vm.codeStatus
                  ? _c(VProgressCircular, {
                      staticStyle: {
                        clear: "both",
                        position: "absolute",
                        top: "3px",
                        right: "0",
                        "margin-right": "355px",
                      },
                      attrs: { indeterminate: "", color: "white", size: "25" },
                    })
                  : _vm._e(),
                _vm.savedToolTime <= 0 && !_vm.isOnPrem
                  ? _c(
                      "div",
                      { staticStyle: { clear: "both" } },
                      [
                        _c(
                          VCard,
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "30%",
                              right: "30%",
                              width: "100%",
                              height: "50%",
                            },
                            attrs: { "max-width": "515px" },
                          },
                          [
                            _c("PaymentToolTime", {
                              on: { close: _vm.closePaymentTool },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          height: "100%",
                          "background-color": "#1e1e1e",
                        },
                      },
                      [
                        _vm.detectedMoving
                          ? _c(
                              "div",
                              { staticStyle: { height: "100%" } },
                              [
                                _c("vue-friendly-iframe", {
                                  staticStyle: {
                                    height: "100%",
                                    width: "100%",
                                  },
                                  attrs: {
                                    src: _vm.location,
                                    frameborder: "0",
                                    gesture: "media",
                                    allow: "encrypted-media",
                                  },
                                  on: { load: _vm.onLoad },
                                }),
                                _vm.startCounting && _vm.isOnPrem
                                  ? _c("billing-counter", {
                                      staticClass: "counter",
                                      staticStyle: { top: "5px" },
                                      attrs: {
                                        hashName: _vm.$route.query.param,
                                        "project-name":
                                          _vm.$route.query.projectName,
                                        propsUserInfo: _vm.userInfo,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "45%",
                                  right: "45%",
                                  "text-align": "-webkit-center",
                                },
                              },
                              [
                                _c(
                                  VIcon,
                                  {
                                    attrs: { "x-large": "", dark: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setLocked(false)
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-lock-outline")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { color: "white" } },
                                  [
                                    _vm._v(
                                      "\n                            잠금을 해제 하려면 자물쇠\n                            "
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "a",
                                                    _vm._g(
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setLocked(
                                                              false
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                        '클릭'\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          984709500
                                        ),
                                      },
                                      [_c("span", [_vm._v(" 해제 ")])]
                                    ),
                                    _vm._v(
                                      "\n                            하세요\n                        "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { color: "white" } },
                                  [
                                    _vm._v(
                                      "\n                            자동 잠금\n                            "
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "a",
                                                    _vm._g(
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setAutoLock()
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                        '변경'\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3315039371
                                        ),
                                      },
                                      [_c("span", [_vm._v(" 클릭  ")])]
                                    ),
                                    _vm._v(
                                      "\n                            (" +
                                        _vm._s(_vm.autoSettingTime) +
                                        ")\n                        "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ]
                    ),
              ],
              1
            )
          : _c(
              "div",
              {
                staticStyle: {
                  height: "100%",
                  "text-align": "center",
                  "background-color": "#1e1e1e",
                },
              },
              [
                _c(
                  VProgressCircular,
                  {
                    staticStyle: {
                      "margin-top": "25%",
                      color: "#1976d2 !important",
                    },
                    attrs: { size: 130, color: "primary", indeterminate: "" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.checkTime) +
                        "초 진행중...\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
      ]),
      _c(
        VDialog,
        {
          attrs: { "max-width": "550" },
          on: {
            "click:outside": function ($event) {
              return _vm.closeIdeResourceDialog()
            },
          },
          model: {
            value: _vm.ideResourceDialog,
            callback: function ($$v) {
              _vm.ideResourceDialog = $$v
            },
            expression: "ideResourceDialog",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "-webkit-center" } },
            [
              _c("IDEResourceDialog", {
                attrs: { ide: false },
                on: { submit: _vm.closeIdeResourceDialog },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }