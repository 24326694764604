import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VCard,
        [
          _c(VCardTitle, [
            _c("div", { staticClass: "main-title" }, [
              _vm._v("Resource Size Setting"),
            ]),
          ]),
          _c(
            VCardText,
            [
              _c(
                VRow,
                { staticStyle: { width: "100%" } },
                [
                  _vm.ide
                    ? _c(
                        VCol,
                        { staticStyle: { "max-width": "50%" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "sub-title",
                              staticStyle: {
                                "text-align-last": "start",
                                "margin-top": "8px",
                              },
                            },
                            [_vm._v("IDE")]
                          ),
                          _c(VDivider),
                          _c(
                            VRadioGroup,
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                "margin-left": "5px",
                              },
                              model: {
                                value: _vm.quotaSize,
                                callback: function ($$v) {
                                  _vm.quotaSize = $$v
                                },
                                expression: "quotaSize",
                              },
                            },
                            [
                              _c(VRadio, {
                                attrs: { value: "low" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("div", [
                                            _vm._v("Low "),
                                            _c(
                                              "strong",
                                              { staticClass: "success--text" },
                                              [_vm._v("2 CPU")]
                                            ),
                                            _vm._v(" / "),
                                            _c(
                                              "strong",
                                              { staticClass: "success--text" },
                                              [_vm._v("4 Gb")]
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1512662413
                                ),
                              }),
                              _c(VRadio, {
                                attrs: { value: "medium" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("div", [
                                            _vm._v("Medium "),
                                            _c(
                                              "strong",
                                              { staticClass: "primary--text" },
                                              [_vm._v("4 CPU")]
                                            ),
                                            _vm._v(" / "),
                                            _c(
                                              "strong",
                                              { staticClass: "primary--text" },
                                              [_vm._v("8 Gb")]
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2840117390
                                ),
                              }),
                              _c(VRadio, {
                                attrs: { value: "high" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("div", [
                                            _vm._v("High "),
                                            _c(
                                              "strong",
                                              { staticClass: "error--text" },
                                              [_vm._v("8 CPU")]
                                            ),
                                            _vm._v(" / "),
                                            _c(
                                              "strong",
                                              { staticClass: "error--text" },
                                              [
                                                _vm._v(
                                                  "16\n                                    Gb"
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4204058492
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VCol,
                    { style: _vm.k8sStyle },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sub-title",
                          staticStyle: {
                            "text-align-last": "start",
                            "margin-top": "8px",
                          },
                        },
                        [_vm._v("kubernetes")]
                      ),
                      _c(VDivider),
                      _c(
                        VRadioGroup,
                        {
                          staticStyle: {
                            "margin-left": "5px",
                            "margin-top": "20px",
                          },
                          model: {
                            value: _vm.toolQuotaSize,
                            callback: function ($$v) {
                              _vm.toolQuotaSize = $$v
                            },
                            expression: "toolQuotaSize",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { value: "none" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [_c("div", [_vm._v("사용 안함")])]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: "low" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", [
                                      _vm._v("Low "),
                                      _c(
                                        "strong",
                                        { staticClass: "success--text" },
                                        [_vm._v("2 CPU")]
                                      ),
                                      _vm._v(" / "),
                                      _c(
                                        "strong",
                                        { staticClass: "success--text" },
                                        [_vm._v("4 Gb")]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: "medium" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", [
                                      _vm._v("Medium "),
                                      _c(
                                        "strong",
                                        { staticClass: "primary--text" },
                                        [_vm._v("4 CPU")]
                                      ),
                                      _vm._v(" / "),
                                      _c(
                                        "strong",
                                        { staticClass: "primary--text" },
                                        [_vm._v("8 Gb")]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: "high" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", [
                                      _vm._v("High "),
                                      _c(
                                        "strong",
                                        { staticClass: "error--text" },
                                        [_vm._v("8 CPU")]
                                      ),
                                      _vm._v(" / "),
                                      _c(
                                        "strong",
                                        { staticClass: "error--text" },
                                        [
                                          _vm._v(
                                            "16\n                                    Gb"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "start", "margin-left": "16%" },
                },
                [
                  _vm.ide && _vm.isChangedIDE
                    ? _c(
                        "div",
                        [
                          _c(VIcon, { attrs: { small: "", color: "red" } }, [
                            _vm._v(" mdi-alert"),
                          ]),
                          _vm._v(
                            "\n                    IDE Resource Size 변경시 기존의 "
                          ),
                          _c("b", [_vm._v("Process kill")]),
                          _vm._v(" 됩니다.\n                "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(
                    "\n                설정된 'Resource Size'에 따라 소진 시간("
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.quotaSizeText))]),
                  _vm._v(")됩니다.\n            "),
                ]
              ),
            ],
            1
          ),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { color: "green darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeResourceQuota()
                    },
                  },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }