import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.usedTime
    ? _c(
        "div",
        { style: _vm.getTimeStyle },
        [
          _c(
            VMenu,
            {
              attrs: {
                "close-on-content-click": "",
                "open-on-hover": !_vm.isEnterpriseBillingCounter,
                "nudge-width": 150,
                "offset-y": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(VRow, { staticStyle: { margin: "auto" } }, [
                          _c(
                            "div",
                            _vm._g(_vm._b({}, "div", attrs, false), on),
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.usedTime) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                876749780
              ),
              model: {
                value: _vm.autoPayMenu,
                callback: function ($$v) {
                  _vm.autoPayMenu = $$v
                },
                expression: "autoPayMenu",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VList,
                    [
                      _c(
                        VListItem,
                        [
                          _c("div", [
                            _vm._v(
                              " Remain: " +
                                _vm._s(
                                  _vm.toolTime ? _vm.toolTime.toFixed(2) : ""
                                )
                            ),
                          ]),
                          _vm.showAutoPay &&
                          _vm.isAvailablePaid &&
                          !_vm.isEnterpriseBillingCounter
                            ? _c(
                                VBtn,
                                {
                                  staticStyle: { "margin-left": "70px" },
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.autoPayToolTime()
                                    },
                                  },
                                },
                                [_vm._v("코인 사용")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }